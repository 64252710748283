<template>
  <div>
    <TableTrasferimentiUnaSoc
      @getTrasferimentiUnaSocietaList="getTrasferimentiUnaSocietaList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableTrasferimentiUnaSoc from "@/components/components-fit/società/trasferimenti/TableTrasferimentiUnaSoc.vue";

export default defineComponent({
  name: "trasferimenti-societa",
  components: {
    TableTrasferimentiUnaSoc,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Trasferimenti Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(
      () => store.getters.rowsToSkipTrasferimentiUnaSoc
    );
    const fetchRows = computed(
      () => store.getters.fetchRowsTrasferimentiUnaSoc
    );
    const sortColumn = computed(
      () => store.getters.sortColumnTrasferimentiUnaSoc
    );
    const sortOrder = computed(
      () => store.getters.sortOrderTrasferimentiUnaSoc
    );

    const getTrasferimentiUnaSocietaList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.societa.id,
          stagione: stagione.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.SOCIETA_TRASFERIMENTI_LIST,
        itemName: "societa_trasferimenti_list",
      });
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      getTrasferimentiUnaSocietaList();
    });

    const resetFilters = () => {
      store.commit("resetFiltersTrasferimentiUnaSoc");
      getTrasferimentiUnaSocietaList();
    };
    getTrasferimentiUnaSocietaList();

    return {
      getTrasferimentiUnaSocietaList,
      resetFilters,
    };
  },
});
</script>
