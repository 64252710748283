<template>
  <div>
    <div class="text-end" style="margin-right: 19px">
      <div class="text-end mt-4" style="margin-right: 19px">
        Visualizzati
        <b v-if="record > 0">
          {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
          -
          {{
            rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
          }}
        </b>
        <b v-else> 0 </b>
        elementi di <b>{{ record }}</b>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        societa_trasferimenti_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="societa_trasferimenti_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsTrasferimentiUnaSoc"
        @current-change="setCurrentPageTrasferimentiUnaSoc"
      >
        <template v-slot:cell-nominativo_completo="{ row: data }"
          ><div class="tab-long">{{ data.nominativo_completo }}</div></template
        >
        <template v-slot:cell-cod_tessera="{ row: data }"
          ><div>{{ data.cod_tessera }}</div></template
        >
        <template v-slot:cell-tipo_tessera="{ row: data }"
          ><div class="tab-long">
            {{ data.tipo_tessera }} / {{ data.tipologia }} /
            {{ data.disciplina }}
          </div></template
        >
        <template v-slot:cell-stato="{ row: data }"
          ><div class="tab-long">{{ data.stato }}</div></template
        >
      </Datatable>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "table-trasferimenti-una-soc",
  components: { Datatable },
  emits: ["getTrasferimentiUnaSocietaList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsTrasferimentiUnaSoc = (e) => {
      store.commit("setFetchRowsTrasferimentiUnaSoc", e);
      emit("getTrasferimentiUnaSocietaList");
    };
    const setCurrentPageTrasferimentiUnaSoc = (page) => {
      store.commit("setCurrentPageTrasferimentiUnaSoc", page);
      emit("getTrasferimentiUnaSocietaList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnTrasferimentiUnaSoc", columnName);
      store.commit("setSortOrderTrasferimentiUnaSoc", order);
      emit("getTrasferimentiUnaSocietaList");
    };

    const tableHeader = ref([
      {
        name: "Tesserato",
        key: "nominativo_completo",
      },
      {
        name: "Tessera",
        key: "cod_tessera",
      },
      {
        name: "Tipo tessera",
        key: "tipo_tessera",
        sortable: false,
      },

      {
        name: "Stato",
        key: "stato",
        sortable: false,
      },
    ]);

    return {
      societa_trasferimenti_list: computed(() =>
        store.getters.getStateFromName("resultssocieta_trasferimenti_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedsocieta_trasferimenti_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordsocieta_trasferimenti_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statussocieta_trasferimenti_list")
      ),

      currentPage: computed(() => store.getters.currentPageTrasferimentiUnaSoc),
      rowsToSkip: computed(() => store.getters.rowsToSkipTrasferimentiUnaSoc),
      fetchRows: computed(() => store.getters.fetchRowsTrasferimentiUnaSoc),
      sortColumn: computed(() => store.getters.sortColumnTrasferimentiUnaSoc),
      sortOrder: computed(() => store.getters.sortOrderTrasferimentiUnaSoc),
      setFetchRowsTrasferimentiUnaSoc,
      setCurrentPageTrasferimentiUnaSoc,
      setSortOrderColumn,
      tableHeader,
      isEnabled,
    };
  },
};
</script>
